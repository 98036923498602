import React from "react"
import FormContact from '../formContact/FormContact'
import "./sectionContact.scss"
import {StaticImage} from "gatsby-plugin-image";


class SectionContact extends React.Component {

    render() {
        return (
            <div className="contactSection">
                <div className="copy">
                    <div className="copyTitle">
                        <h2>Design a Digital Treatment</h2>
                    </div>
                    <p>Let us know how we can help in designing a digital treatment.</p>
                </div>
                <div className="contactForm">
                    <FormContact/>
                </div>
                <div className="imagePhones">
                    <StaticImage
                        src="../../assets/images/page-images/phones.png"
                        alt="Phones with Welltot App"
                        placeholder="blurred"
                        layout="fullWidth"
                        quality={100}
                    />
                </div>
                <div className="imageBackground">
                    <StaticImage
                        src="../../assets/images/page-images/woman-holding-baby.jpg"
                        alt="Phones with Welltot App"
                        placeholder="blurred"
                        layout="fullWidth"
                        quality={100}
                    />
                </div>
            </div>
        )
    }
}

export default SectionContact
