import React from "react"
import "./sectionGatherAnalyzeRespond.scss"


class SectionGatherAnalyzeRespond extends React.Component {

    render() {
        return (

            <div className="sectionGatherAnalyzeRespond">
                <div className="headCopy">
                    <h2>Gather, analyze, & respond to biometric data</h2>
                </div>
                <div className="whyContainer drag-drop">
                    <div className="copy">
                        <h3>Drag & Drop Treatment Builder</h3>
                        <p>Programmable by a non-engineer allows for fast data collection, algorithm development, & system integrations.</p>
                    </div>
                </div>
                <div className="whyContainer continuous-monitoring">
                    <div className="copy">
                        <h3>Non-invasive Continuous monitoring</h3>
                        <p>Monitoring across multiple biometrics allows for better data resolution and algorithms.</p>
                    </div>

                </div>
                <div className="whyContainer information-where-you-want-it">
                    <div className="copy">
                        <h3>Information where and when you want it</h3>
                        <p>Apps, integrations, texts, and emails allow doctors, researchers, and parents to have the information needed.</p>
                    </div>
                </div>


            </div>

        )
    }
}

export default SectionGatherAnalyzeRespond