import React from "react"
import "./sectionBillboard1.scss"
import AffirmXHVideo from "./assets/affirmxh.mp4"


class SectionBillboard1 extends React.Component {

    render() {
        return (

            <div className="SectionBillboard1">
                <div className="copyBillboard">
                    <h1>Gather, Analyze, and Respond<br/>to Biometric Data</h1>
                </div>


                <div className="video">
                    <video
                        autoPlay
                        muted
                        loop
                        preload
                        playsInline
                        width="100%"
                        >
                        <source src={AffirmXHVideo} type="video/mp4" />
                    </video>
                </div>
            </div>

        )
    }
}

export default SectionBillboard1






