import React from "react"
import "./sectionAcquisition.scss"


class SectionAcquisition extends React.Component {

    render() {
        return (

            <div className="SectionAcquisition">
                <div className="copyBillboard">
                    <a href="https://blog.life365.health/en/life365-announces-strategic-acquisition-of-affirmxh-to-enhance-health-solutions" target="_blank" rel="noreferrer"><h2>Life365 Announces Strategic Acquisition of AffirmXH to
                        Enhance Health Solutions.</h2></a>
                </div>
            </div>

        )
    }
}

export default SectionAcquisition






