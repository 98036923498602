import React from "react"
import "./sectionOpenEcosystem.scss"
import {StaticImage} from "gatsby-plugin-image";
import Logos from "./images/logos.svg"


class SectionOpenEcosystem extends React.Component {

    render() {
        return (

            <div className="sectionOpenEcosystem">
                <div className="container1"></div>
                <div className="container2"></div>
                <div className="container3"></div>
                <div className="container4"></div>
                <div className="continuousTemperatureSensorImage containerImage">
                    <StaticImage
                        className="imageCtm"
                        src="./images/man-with-sensor-overlay-square.jpg"
                        alt="AffirmXH Continuous Temperature Sensor"
                        placeholder="blurred"
                        layout="constrained"
                        quality={100}
                    />
                </div>
                <div className="continuousTemperatureSensorCopy">
                    <div className="containerCopy">
                        <h2>Continuous Temperature Sensor for Wellness</h2>
                        <ul>
                            <li>50+ temperature readings per hour</li>
                            <li>Disposable patch</li>
                            <li>No maintenance & no re-charging</li>
                            <li>Easy to use - Mobile phone or cellular hub connected</li>
                            <li>Made in the USA</li>
                        </ul>
                    </div>
                </div>
                <div className="openEcosystemCopy containerCopy">
                    <h2>An Open Ecosystem<br/>of Devices, Algorithms, & Data</h2>
                </div>
                <div className="openEcosystemImage containerImage">
                    <img className="imageOpenEcosystem" src={Logos} alt="Research" />
                </div>
            </div>

        )
    }
}

export default SectionOpenEcosystem