import React from "react"
import "./sectionModelForHealth.scss"
import {StaticImage} from "gatsby-plugin-image";


class SectionModelForHealth extends React.Component {

    render() {
        return (

            <div className="sectionModelForHealth">
                <div className="copy">
                    <div className="copyTitle">
                        <h2>Our model<br/><span className="subheader">for better health</span></h2>
                    </div>

                    <p>We give individuals better insight into their health and wellness goals, driving accelerated adoption of individualized medicine. To make this meaningful difference in health, we follow a <strong>framework of feedback loops</strong> typically used in aerospace & engineering but applied to healthcare.</p>
                    <p>AffirmXH enhances this feedback loop though four <strong>increasing levels of patient value.</strong></p>

                    <ol>
                        <li>Establish a normal feedback loop.</li>
                        <li>Increase the accuracy of the feedback loop by identifying and measuring additional meaningful variables.</li>
                        <li>Increase velocity of feedback loop through population analysis/ machine learning.</li>
                        <li>Increasing personalization of feedback loop through AI.</li>
                    </ol>

                </div>
                <div className="model">
                    <StaticImage
                        className="modelForHealth"
                        src="../../assets/images/page-images/model.png"
                        alt="Model for Better Health"
                        placeholder="blurred"
                        layout="constrained"
                        quality={100}
                    />
                </div>
            </div>

        )
    }
}

export default SectionModelForHealth
