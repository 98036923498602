import React from "react"
import "./sectionBuiltInTemplates.scss"
import IconOncology from "../sectionBuiltInTemplates/images/oncology.svg"
import IconMaternity from "../sectionBuiltInTemplates/images/maternity.svg"
import IconDiabetes from "../sectionBuiltInTemplates/images/diabetes.svg"
import IconSickleCellDisease from "../sectionBuiltInTemplates/images/sickle-cell-disease.svg"
import IconCardiac from "../sectionBuiltInTemplates/images/cardiac.svg"
import IconAdherence from "../sectionBuiltInTemplates/images/adherence.svg"
import IconClinicalTrials from "../sectionBuiltInTemplates/images/clinical-trials.svg"
import IconResearch from "../sectionBuiltInTemplates/images/research.svg"


class SectionBuiltInTemplates extends React.Component {

    render() {
        return (

            <div className="sectionBuiltInTemplates">
                <div className="headCopy">
                    <h2>Built in templates to kickstart the  treatment builder*</h2>
                </div>
                <div className="footnoteCopy">
                    <p>*We can also help you craft the perfect journey</p>
                </div>
                <div className="templateAreas">

                    <div className="templateArea oncology">
                        <div className="icon">
                            <img src={IconOncology} alt="Oncology" />
                        </div>
                        <div className="copy">
                            <h3>Oncology</h3>
                        </div>
                    </div>
                    <div className="templateArea maternity">
                        <div className="icon">
                            <img src={IconMaternity} alt="Maternity" />
                        </div>
                        <div className="copy">
                            <h3>Maternity</h3>
                        </div>
                    </div>
                    <div className="templateArea diabetes">
                        <div className="icon">
                            <img src={IconDiabetes} alt="Diabetes" />
                        </div>
                        <div className="copy">
                            <h3>Diabetes</h3>
                        </div>
                    </div>
                    <div className="templateArea sickle-cell-disease">
                        <div className="icon">
                            <img src={IconSickleCellDisease} alt="Sickle Cell Disease" />
                        </div>
                        <div className="copy">
                            <h3>Sickle Cell Disease</h3>
                        </div>
                    </div>
                    <div className="templateArea cardiac">
                        <div className="icon">
                            <img src={IconCardiac} alt="Cardiac" />
                        </div>
                        <div className="copy">
                            <h3>Cardiac</h3>
                        </div>
                    </div>
                    <div className="templateArea adherence">
                        <div className="icon">
                            <img src={IconAdherence} alt="Adherence" />
                        </div>
                        <div className="copy">
                            <h3>Adherence</h3>
                        </div>
                    </div>
                    <div className="templateArea clinical-trials">
                        <div className="icon">
                            <img src={IconClinicalTrials} alt="Clinical Trials" />
                        </div>
                        <div className="copy">
                            <h3>Clinical Trials</h3>
                        </div>
                    </div>
                    <div className="templateArea research">
                        <div className="icon">
                            <img src={IconResearch} alt="Research" />
                        </div>
                        <div className="copy">
                            <h3>Research</h3>
                        </div>
                    </div>

                </div>
            </div>

        )
    }
}

export default SectionBuiltInTemplates