import React from "react"
import "./sectionFlow.scss"
import Flow1 from "./flow-1.svg"


class SectionFlow extends React.Component {

    render() {
        return (

            <div className="SectionFlow">
                <div className="imgFlow1">
                    <img src={Flow1} alt="overlay" />
                </div>
                <div className="copyBillboard">
                    <h1>No-Code Digital Treatment*</h1>
                </div>
                <div className="copyFootnote">
                    <p className="footnote">*For wellness and self-management</p>
                </div>
            </div>

        )
    }
}

export default SectionFlow






