import React from "react"
import { Helmet } from "react-helmet"
import LayoutMain from "../components/layouts/LayoutMain"
import SectionContact from "../components/sectionContact/SectionContact";
import SectionBillboard1 from "../components/sectionBillboard1/SectionBillboard1";
import SectionFlow from "../components/sectionFlow/SectionFlow";
import SectionModelForHealth from "../components/sectionModelForHealth/SectionModelForHealth";
import SectionGatherAnalyzeRespond from "../components/sectionGatherAnalzyeRespond/sectionGatherAnalyzeRespond";
import SectionBuiltInTemplates from "../components/sectionBuiltInTemplates/sectionBuiltInTemplates";
import SectionOpenEcosystem from "../components/sectionOpenEcoysytem/sectionOpenEcosystem";
import SectionAcquisition from "../components/sectionAcquisition/sectionAcquisition";

import "../styles/homepage.scss"





class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showheadline:1
    }
  }


  componentDidMount() {
    this.interval = setInterval(() => this.setState({ showheadline: (this.state.showheadline===3 ? 1 : this.state.showheadline + 1) }), 5000);
  }
  componentWillUnmount() {
    clearInterval(this.interval);
  }



  render() { 
    return (
      <LayoutMain>
        <Helmet>
          <title>AffirmXH</title>
          <meta
            name="description"
            content="AffirmXH brings meaning to your everyday physiology -- Experience Health"
          />
          <bodyAttributes
            className="homepage"
          />
        </Helmet>

        <div className="container-homepage">


          <div className="homepageAcquisition">
            <SectionAcquisition/>
          </div>

          <div className="homepageBillboard1">
            <SectionBillboard1/>
          </div>


          <div className="homepageFlow">
            <SectionFlow/>
          </div>

          <div className="homepagePlatform" id="platform">
            <SectionGatherAnalyzeRespond/>
          </div>

          <div className="homepageBuiltInTemplates" id="built-in-templates">
            <SectionBuiltInTemplates/>
          </div>

          <div className="homepageOpenEcosystem" id="open-ecosystem">
            <SectionOpenEcosystem/>
          </div>

          <div className="homepageModelForHealth" id="our-model">
            <SectionModelForHealth/>
          </div>

          <div className="homepageContact" id="contact">
            <SectionContact/>
          </div>

        </div>
      </LayoutMain>
    )
  }
}

export default IndexPage
